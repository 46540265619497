import { BoxV2 as Box, Flex } from 'portal-commons';
import { FunctionComponent } from 'react';
import {
  faArrowLeft,
  faEnvelope,
  faGlobe,
  faPhone,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface BrandAssetsEmulatorProps {
  logo?: string;
  banner?: string;
}

interface LogoProps {
  logo?: string;
}

interface BannerProps {
  banner?: string;
}

const Logo: FunctionComponent<LogoProps> = ({ logo }) => (
  <Box
    sx={{
      position: 'absolute',
      left: '50%',
      bottom: 0,
      width: 56,
      height: 56,
      border: '1px solid lightgray',
      borderRadius: '50%',
      background: logo ? 'none' : 'white',
      transform: 'translate(-50%, 40%)',
      overflow: 'hidden',
    }}
  >
    {logo ? (
      <img
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        src={logo}
      />
    ) : null}
  </Box>
);

const Banner: FunctionComponent<BannerProps> = ({ banner }) =>
  banner ? (
    <img
      style={{ width: '100%', aspectRatio: '45 / 14', objectFit: 'cover' }}
      src={banner}
    />
  ) : (
    <Box
      sx={{
        width: '100%',
        aspectRatio: '45 / 14',
        borderBottom: banner ? 'none' : '1px solid lightgray',
        background: banner ? 'none' : 'white',
      }}
    />
  );

const Container: FunctionComponent = ({ children }) => (
  <Box
    sx={{
      position: 'relative',
      width: 'fit-content',
      height: 'fit-content',
      padding: '48px 16px 16px 16px',
      border: '2px solid lightgray',
      borderRadius: 16,
      boxSizing: 'border-box',
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        top: 20,
        left: '50%',
        width: 80,
        height: 8,
        borderRadius: 4,
        background: 'lightgray',
        transform: 'translateX(-50%)',
      }}
    />
    <Flex
      sx={{
        flexDirection: 'column',
        width: 290,
        height: 620,
        border: '1px solid lightgray',
        overflow: 'hidden',
      }}
    >
      {children}
    </Flex>
  </Box>
);

const NavigationBar = () => (
  <Flex
    sx={{
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      height: 48,
      boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
      fontSize: 16,
      lineHeight: '19px',
      zIndex: 5,
    }}
  >
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: 48,
        height: 48,
      }}
    >
      <FontAwesomeIcon size="lg" icon={faArrowLeft} />
    </Flex>
    <Box>Info & options</Box>
  </Flex>
);

const BrandAssets: FunctionComponent<LogoProps & BannerProps> = ({
  logo,
  banner,
}) => (
  <Box sx={{ position: 'relative', marginBottom: 23 }}>
    <Banner banner={banner} />
    <Logo logo={logo} />
  </Box>
);

const BrandInfo = () => (
  <Flex
    sx={{
      flexDirection: 'column',
      alignItems: 'center',
      gap: 8,
      marginTop: 20,
    }}
  >
    <Box sx={{ fontSize: 16, lineHeight: '16px' }}>Brand Name</Box>
    <Box sx={{ color: 'darkgray' }}>Short Description</Box>
  </Flex>
);

const ActionBar = () => (
  <Flex
    sx={{
      alignItems: 'center',
      justifyContent: 'space-evenly',
      marginTop: 20,
      color: '#e91b0c',
    }}
  >
    <Flex sx={{ flexDirection: 'column', alignItems: 'center', gap: 4 }}>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: 36,
          height: 36,
        }}
      >
        <FontAwesomeIcon size="lg" icon={faPhone} />
      </Flex>
      <Box>Call</Box>
    </Flex>
    <Flex sx={{ flexDirection: 'column', alignItems: 'center', gap: 4 }}>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: 36,
          height: 36,
        }}
      >
        <FontAwesomeIcon size="lg" icon={faGlobe} />
      </Flex>
      <Box>Website</Box>
    </Flex>
    <Flex sx={{ flexDirection: 'column', alignItems: 'center', gap: 4 }}>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: 36,
          height: 36,
        }}
      >
        <FontAwesomeIcon size="lg" icon={faEnvelope} />
      </Flex>
      <Box>Email</Box>
    </Flex>
  </Flex>
);

const ContactPanel = () => (
  <Flex sx={{ flexDirection: 'column', marginTop: 20 }}>
    <Flex sx={{ height: 48 }}>
      <Flex
        sx={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          borderBottom: '2px solid #e91b0c',
          color: 'dodgerblue',
        }}
      >
        Info
      </Flex>
      <Flex
        sx={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          color: 'darkgray',
        }}
      >
        Options
      </Flex>
    </Flex>
    <Flex
      sx={{
        alignItems: 'center',
        height: 48,
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: 48,
          height: 48,
        }}
      >
        <FontAwesomeIcon size="lg" icon={faPhone} />
      </Flex>
      <Flex
        sx={{
          flex: 1,
          alignItems: 'center',
          height: '100%',
          borderBottom: '1px solid lightgray',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            gap: 4,
            fontSize: 12,
            lineHeight: '14px',
          }}
        >
          <Box>+15555555555</Box>
          <Box sx={{ color: 'darkgray' }}>Primary phone</Box>
        </Flex>
      </Flex>
    </Flex>
    <Flex
      sx={{
        alignItems: 'center',
        height: 48,
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: 48,
          height: 48,
        }}
      >
        <FontAwesomeIcon size="lg" icon={faGlobe} />
      </Flex>
      <Flex
        sx={{
          flex: 1,
          alignItems: 'center',
          height: '100%',
          borderBottom: '1px solid lightgray',
        }}
      >
        <Flex
          sx={{
            flex: 1,
            flexDirection: 'column',
            gap: 4,
            fontSize: 12,
            lineHeight: '14px',
          }}
        >
          <Box>https://example.com/</Box>
          <Box sx={{ color: 'darkgray' }}>Website</Box>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
);

const BrandAssetsEmulator: FunctionComponent<BrandAssetsEmulatorProps> = ({
  logo,
  banner,
}) => {
  return (
    <Container>
      <NavigationBar />
      <BrandAssets logo={logo} banner={banner} />
      <BrandInfo />
      <ActionBar />
      <ContactPanel />
    </Container>
  );
};

export default BrandAssetsEmulator;
