import { BoxV2 as Box, Flex } from 'portal-commons';
import { FunctionComponent, useState } from 'react';
import { faFileImage } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BrandAssetList from '../BrandAssetList';
import BrandAssetsEmulator from '../BrandAssetsEmulator';
import { BrandAsset } from '../../types';
import { S3_ASSETS_PATH } from '../../../../../constants';

interface BrandAssetsEmulatorBlockProps {
  logos: BrandAsset[];
  banners: BrandAsset[];
}

const BrandAssetsEmulatorBlock: FunctionComponent<
  BrandAssetsEmulatorBlockProps
> = ({ logos, banners }) => {
  const [previewLogo, setPreviewLogo] = useState<string>();
  const [previewBanner, setPreviewBanner] = useState<string>();

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        rowGap: '12px',
        padding: '24px 32px',
        border: '1px solid #C0CCD4',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: '8px',
          color: '#4D5659',
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            columnGap: '8px',
            width: '100%',
            paddingBottom: '12px',
            borderBottom: '1px solid #CCCFD0',
          }}
        >
          <FontAwesomeIcon icon={faFileImage} size="xl" />
          <Box sx={{ fontWeight: 600, fontSize: '18px', lineHeight: '21px' }}>
            Brand Asset Emulator
          </Box>
        </Flex>
        <Box sx={{ fontSize: '14px', lineHeight: '16px' }}>
          Try different logos and banners to see how they will look on an RBM
          Agent.
        </Box>
      </Flex>

      <Flex sx={{ columnGap: '10px', maxHeight: '468px', overflow: 'hidden' }}>
        <Flex
          sx={{
            flex: 1,
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <BrandAssetList
            title="LOGO"
            assets={logos}
            onSelect={(index) => {
              // TODO: Set logo for preview
              setPreviewLogo(`${S3_ASSETS_PATH}/images/${logos[index].name}`);
            }}
            onVerify={(index) => {
              // TODO: Prepare logo for verification
            }}
          />
          <BrandAssetList
            title="BANNER"
            assets={banners}
            onSelect={(index) => {
              // TODO: Set banner for preview
              setPreviewBanner(
                `${S3_ASSETS_PATH}/images/${banners[index].name}`
              );
            }}
            onVerify={(index) => {
              // TODO: Prepare banner for verification
            }}
          />
        </Flex>
        <BrandAssetsEmulator logo={previewLogo} banner={previewBanner} />
      </Flex>
    </Flex>
  );
};

export default BrandAssetsEmulatorBlock;
