import { BoxV2 as Box, Flex, useHasPermission } from 'portal-commons';
import { FunctionComponent } from 'react';

import BrandAssetRow from './BrandAssetRow';
import { BrandAssetListHeader } from './types';
import { BrandAsset } from '../../types';

const Headers: BrandAssetListHeader[] = [
  {
    id: 'status',
    label: 'STATUS',
    width: 105,
  },
  {
    id: 'action',
    label: 'ACTION',
    width: 100,
  },
];

const ReadonlyHeaders: BrandAssetListHeader[] = [
  {
    id: 'status',
    label: 'STATUS',
    width: 105,
  },
];

interface BrandAssetListProps {
  title: string;
  assets: BrandAsset[];
  onSelect?: (index: number) => void;
  onVerify?: (index: number) => void;
}

const BrandAssetList: FunctionComponent<BrandAssetListProps> = ({
  title,
  assets,
  onSelect,
  onVerify,
}) => {
  const authorized = useHasPermission('brandDetail.verifyAsset');
  const headers = [
    {
      id: 'name',
      label: title,
    },
    ...(authorized ? Headers : ReadonlyHeaders),
  ];

  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoRows: '38px',
        gridTemplateColumns: headers
          .map((header) =>
            header.width ? `minmax(${header.width}px, min-content)` : '1fr'
          )
          .join(' '),
        minHeight: '228px',
        maxHeight: '228px',
        border: '1px solid #C0CCD4',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#333E41',
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'contents',
          '> *:first-child': {
            borderTopLeftRadius: '10px',
          },
          '> *:last-child': {
            borderTopRightRadius: '10px',
          },
        }}
      >
        {headers.map((header) => (
          <Flex
            key={header.id}
            sx={{
              position: 'sticky',
              top: 0,
              alignItems: 'center',
              paddingLeft: '16px',
              color: '#00667D',
              background: '#E5EFF2',
              fontWeight: 800,
            }}
          >
            {header.label}
          </Flex>
        ))}
      </Box>
      <Box
        sx={{
          display: 'contents',
          '> *:nth-child(even)': {
            '> *': {
              background: '#F2F3F3',
            },
          },
        }}
      >
        {assets.map((logo, index) => (
          <BrandAssetRow
            key={logo.attachmentUuid}
            asset={logo}
            group={title}
            onSelect={() => onSelect?.(index)}
            onVerify={() => onVerify?.(index)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default BrandAssetList;
