import React from 'react';
import { BoxV2 as Box, Flex, Button, RoleGuard } from 'portal-commons';
import BrandAssetsList from './BrandAssetsList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/pro-regular-svg-icons';

type UploaderType = 'logo' | 'banner';
interface Props {
  onOpenUploader: (type: UploaderType) => void;
  onOpenEmulator: () => void;
}

const BrandAssets: React.FC<Props> = ({ onOpenUploader, onOpenEmulator }) => {
  return (
    <Box
      sx={{
        maxWidth: '100%',
        p: 'xxl',
        pt: 'xl',
        mb: 30,
        border: '1px solid #C0CCD4',
        flexBasis: '100%',
        flexGrow: 0,
      }}
      data-testid="brandAssetsContainer"
    >
      <Flex sx={{ justifyContent: 'space-between', mb: 'xxs' }}>
        <Flex
          as="h3"
          sx={{
            alignItems: 'center',
            fontSize: 'H500',
            color: 't.black70',
            fontWeight: 600,
            lineHeight: 'xxl',
            '& svg': {
              fontSize: 'H600',
              mr: 'xs',
            },
          }}
        >
          <FontAwesomeIcon icon={faFileImage} />
          Brand Assets
        </Flex>
        <Flex sx={{ alignItems: 'center', gap: 's' }}>
          <RoleGuard feature="brandDetail.addLogo">
            <Button
              shape="square"
              size="small"
              onClick={() => onOpenUploader('logo')}
              data-testid="addLogoButton"
            >
              Add Logo
            </Button>
          </RoleGuard>
          <RoleGuard feature="brandDetail.addBanner">
            <Button
              shape="square"
              size="small"
              onClick={() => onOpenUploader('banner')}
              data-testid="addBannerButton"
            >
              Add Banner
            </Button>
          </RoleGuard>
        </Flex>
      </Flex>
      <Box
        sx={{
          mb: 'xs',
          fontSize: 'H300',
          color: 'black',
          fontWeight: 400,
        }}
      >
        Add and verify your assets to using them for RBM.
      </Box>
      <BrandAssetsList onOpenEmulator={onOpenEmulator} />
    </Box>
  );
};

export default BrandAssets;
